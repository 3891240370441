import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Lisa Falkson has made a career out of creating amazing voice user interfaces. You can find her fingerprints on the Alexa Communications features, where she leads the team designing the experience. Learn more about her before we welcome her to the SuperBot stage this spring.`}</p>
    <h3><strong parentName="h3">{`How did you get interested in the voice space?`}</strong></h3>
    <p>{`I studied EE (Electrical Engineering) in undergrad and when I went to grad school, I just happened to luck into the right lab. My advisor at UCLA, `}<a parentName="p" {...{
        "href": "https://www.ee.ucla.edu/abeer-alwan/"
      }}>{`Abeer Alwan`}</a>{`, ran the `}<a parentName="p" {...{
        "href": "http://www.seas.ucla.edu/spapl/"
      }}>{`Speech and Auditory Perception Laboratory`}</a>{`, which focused on research on speech recognition, text-to-speech, and hearing/auditory perception. Spending time in that lab piqued my interest in the technology and the all possible industry applications. Over my 20 year career, I’ve done almost everything: tuning recognition parameters, writing grammars/NLU, selling customer solutions, writing demo `}{`&`}{` production code, and designing voice interactions – which is what I focus on now.`}</p>
    <h3><strong parentName="h3">{`How has voice design changed since you started in this space?`}</strong></h3>
    <p>{`The main change has been the technology shift from fixed, rule-based grammars (in the 1990’s and early 2000’s) to statistical language models that recognize more free-form responses. This has revolutionized the space and allowed personal assistants like Siri, Alexa and Google Assistant to thrive. It’s also made voice design a much more interesting and challenging problem!`}</p>
    <h3><strong parentName="h3">{`What are some key tips you would give someone building a voice skill?`}</strong></h3>
    <p>{`Don’t try to do everything; try to do a few things `}{`*`}<strong parentName="p">{`really well.`}</strong>{`*`}{` It’s better to focus on nailing the top use cases than to spend a lot of effort trying to design solutions for a too-broad set of business requirements.`}</p>
    <h3><strong parentName="h3">{`What are you looking forward to at SuperBot?`}</strong></h3>
    <p>{`I’m looking forward to hearing directly from our customers and partners. Listening to what they value about voice interactions helps me design the best possible products and features to meet their needs.`}</p>
    <hr></hr>
    <p><em parentName="p">{`Want to hear more from Lisa? She’s speaking at the fourth annual SuperBot this spring. Register to meet her and get the chance to ask her your questions directly.`}</em></p>
    <div className="wp-block-button aligncenter is-style-squared">
  [Register for SuperBot](https://www.dashbot.io/superbot/sb2020)
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      